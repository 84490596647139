import React from "react"

import Typography from "@material-ui/core/Typography"

import useStyles from "./quote.style"

export default function Quote({ className, quote }) {
  const classes = useStyles()

  return (
    <div className={`${classes.root} ${className}`}>
      <Typography variant="caption" color="inherit">
        {quote}
      </Typography>
    </div>
  )
}
