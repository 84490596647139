export const chunkArray = (inputArray, perChunk) => {
  const result = inputArray.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk)

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])

  return result
}

export const getImage = (imageUrl, big) => {
  if (big) {
    return `${imageUrl}?w=800&q=99`
  }
  return `${imageUrl}?w=300&q=90`
}
