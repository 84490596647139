import React, { useState, useEffect } from "react"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Hidden from "@material-ui/core/Hidden"

import Layout from "../components/layout/layout"

import Quote from "../components/general/quote"
import JoinBanner from "../components/general/joinBanner"

import squaredPicturesOne from "../images/mission/squares-pictures-1.png"
import squaredPicturesTwo from "../images/mission/squares-pictures-2.png"

import useStyles from "./index.style"
import SEO from "../components/seo"
import useWamUrl from "../hooks/useWamUrl"
// import { useTheme } from "@material-ui/core"

export default function Missions() {
  const classes = useStyles()
  // const theme = useTheme()

  const [height, setHeight] = useState(0)
  useEffect(() => {
    setHeight(window.innerHeight)
  }, [])

  const { redirectToWam } = useWamUrl()

  return (
    <Layout animatedNavbar>
      <SEO />
      <div
        className={`${classes.header} ${classes.missionHeader}`}
        style={{ height: height }}
      >
        <div className={classes.textWrapper}>
          <Typography variant="h1" color="inherit" align="center">
            Notre mission
          </Typography>
          <Typography
            className={classes.subtitle}
            variant="h4"
            color="inherit"
            align="center"
          >
            Inshallah est un service d’échange qui tend à faciliter aux
            musulman(e)s la recherche de l’âme sœur en accord avec les valeurs
            humaines et morales de l’Islam.
          </Typography>

          <Button
            classes={{
              root: classes.signUpButton,
            }}
            onClick={() => redirectToWam("join")}
            disableRipple
            disableFocusRipple
          >
            Je m’inscrit !
          </Button>
        </div>
      </div>

      <div className={classes.iPhonesContent}>
        <Grid container spacing={2}>
          <Hidden smDown>
            <Grid className={classes.textColumnMission} item xs={12} md={6}>
              <div className={classes.paragraphBlock}>
                <div className={classes.paragraphTextWrapper}>
                  <Typography variant="h6" component="h3">
                    Une communauté ouverture&nbsp;
                    <span role="img" aria-label="hands up emoji">
                      🙌
                    </span>
                  </Typography>
                  <Typography
                    className={classes.paragraphText}
                    variant="button"
                    component="p"
                  >
                    Il ne faut pas voir dans cet aspect communautaire du site
                    une forme quelconque de communautarisme car bien que
                    solidement ancrés dans notre identité, nous sommes d’autant
                    plus ouvert aux autres et les invitons d’ailleurs à venir à
                    la rencontre et à l’échange tout en restant eux-mêmes.
                  </Typography>
                </div>
              </div>
            </Grid>
          </Hidden>
          <Grid className={classes.columnMission} item xs={12} md={6}>
            <img
              className={classes.squaredPictures}
              src={squaredPicturesOne}
              alt="iPhone with liking animation"
            />
          </Grid>
          <Hidden mdUp>
            <Grid className={classes.textColumnMission} item xs={12} md={6}>
              <div className={classes.paragraphBlock}>
                <div className={classes.paragraphTextWrapper}>
                  <Typography variant="h6" component="h3">
                    Une communauté ouverture&nbsp;
                    <span role="img" aria-label="hands up emoji">
                      🙌
                    </span>
                  </Typography>
                  <Typography
                    className={classes.paragraphText}
                    variant="button"
                    component="p"
                  >
                    Il ne faut pas voir dans cet aspect communautaire du site
                    une forme quelconque de communautarisme car bien que
                    solidement ancrés dans notre identité, nous sommes d’autant
                    plus ouvert aux autres et les invitons d’ailleurs à venir à
                    la rencontre et à l’échange tout en restant eux-mêmes.
                  </Typography>
                </div>
              </div>
            </Grid>
          </Hidden>

          <Grid className={classes.columnMission} item xs={12} md={6}>
            <img
              className={classes.squaredPictures}
              src={squaredPicturesTwo}
              alt="iPhone with a scrolled user profile"
            />
          </Grid>
          <Grid className={classes.textColumnMission} item xs={12} md={6}>
            <div className={classes.paragraphBlock}>
              <div className={classes.paragraphTextWrapper}>
                <Typography variant="h6" component="h3">
                  Un lieu de partages et de rencontres&nbsp;
                  <span role="img" aria-label="book emoji">
                    👋
                  </span>
                </Typography>
                <Typography
                  className={classes.paragraphText}
                  variant="button"
                  component="p"
                >
                  L’échange entre les différents sexes, générations et races
                  fait partie des raisons de la divine création même s’il ne
                  conduit pas automatiquement au mariage. Il peut être le fruit
                  d’un partage fraternel et culturel.
                </Typography>
                <Quote
                  className={classes.iPhoneQuote}
                  quote="“Ô hommes ! Nous vous avons créés d’un homme et d’une femme, et Nous avons fait de vous des nations et des tribus, pour que vous vous entre-connaissiez.” — S.49 V13."
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className={classes.joinBannerWrapperMission}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <JoinBanner />
          </Grid>
        </Grid>
      </div>

      <div className={classes.fullWidthRow}>
        <Grid container spacing={2}>
          <Grid item md={3} implementation="css" mdDown component={Hidden} />
          <Grid item xs={12} md={6}>
            <Typography
              className={classes.fullWidthColumnTitle}
              variant="h1"
              component="h2"
              align="center"
            >
              Nos engagements, nos responsabilités&nbsp;
              <span role="img" aria-label="hands shaking emoji">
                🤝
              </span>
            </Typography>
          </Grid>
          <Grid item md={3} implementation="css" mdDown component={Hidden} />
        </Grid>
      </div>

      <div className={classes.missionTextContent}>
        <Grid container spacing={2}>
          <Grid item md={3} implementation="css" mdDown component={Hidden} />
          <Grid item xs={12} md={6}>
            <div className={classes.paragraphBlock}>
              <div className={classes.paragraphTextWrapper}>
                <Typography variant="button" component="p">
                  En mettant en œuvre ce projet nous avons avant tout le souhait
                  qu’il puisse être agréé par notre Seigneur, son noble messager
                  et être utile pour notre communauté :
                </Typography>
                <Quote
                  className={classes.textContentQuote}
                  quote="« Dis : Œuvrez et sachez qu’Allah verra vos œuvres ainsi que son messager et les croyants. ». S.9 V.105"
                />
                <Typography variant="button" component="p">
                  Ce projet est d’autant plus noble que délicat. Sa noblesse lui
                  vient de la parole d’Allah :
                </Typography>
                <Quote
                  className={classes.textContentQuote}
                  quote="« O vous les hommes nous vous avons crée à partir d’un homme et d’une femme, réparti en nations et tribus pour que vous vous entre-connaissiez ». S.49 V.13"
                />
                <Typography variant="button" component="p">
                  Mais il est aussi délicat dans la mesure où, sans encadrement
                  islamique, il risque à terme de dévier et de se différencier
                  des autres services de rencontre que par le nom. De ce fait,
                  il est impératif d’être à la hauteur du nom qu’il porte, car
                  Inshallah renvoie à la notion du pouvoir absolu, sans lequel
                  rien ne peut aboutir. Et qui plus est ce nom est un symbole
                  fort de l’appartenance à l’Islam.
                  <br />
                  <br />
                  C’est pour cela que nous devons avec toute la rigueur
                  nécessaire répondre aux exigences de l’islam dans le domaine
                  de rencontre entre les deux sexes : nous disons oui à la
                  rencontre non à la perversité, oui à l’échange et au dialogue
                  non à l’immoralité sous toutes ses formes.
                  <br />
                  <br />
                  Par ailleurs, la forte fréquentation que connaît le service,
                  nous place indéniablement dans une posture responsable devant
                  la communauté, notre foi et notre Seigneur car comme le
                  souligne si bien notre maître le bien-aimé Prophète (psl) :
                </Typography>
                <Quote
                  className={classes.textContentQuote}
                  quote="« Celui qui a à son auditoire une dizaine de personnes sera considéré auprès d’Allah comme un guide qui devra rendre des comptes. »"
                />
                <Typography variant="button" component="p">
                  Hadith, et les guides il en existe deux, celui du bien et
                  celui de son contraire. De certains le seigneur dit :
                </Typography>
                <Quote
                  className={classes.textContentQuote}
                  quote="« Nous avons désigné parmi eux des dirigeants qui guident les gens par Notre ordre aussi longtemps qu’ils enduraient ». S.32 V.24 et pour d’autres, le seigneur dit : « Et nous fîmes d’eux des guides qui appellent vers la fournaise et le jour dernier ils ne seront pas secourus ». S.28 V.41"
                />
                <Typography variant="button" component="p">
                  En tant qu’administrateur, il ne doit pas nous suffire tout
                  simplement de favoriser la rencontre des sexes, qui est
                  impérative il faut le souligner, mais il faut savoir encadrer
                  cette rencontre pour éviter qu’elle ne se pervertisse. Notre
                  devoir est d’accompagner les concernés pour que inshallah ils
                  puissent connaître l’aboutissement.
                  <br />
                  <br />
                  C’est bien donc là la noblesse de la tâche, il est question de
                  morale et d’appartenance islamique au-delà de la recherche de
                  renommé ou de fonds.
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item md={3} implementation="css" mdDown component={Hidden} />
        </Grid>
      </div>
    </Layout>
  )
}
