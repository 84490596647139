import { makeStyles } from "@material-ui/core/styles"
import landingBackground from "../images/landing/landing-bckg.jpg"
import missionBackground from "../images/mission/mission-bckg.jpg"
import presseBackground from "../images/presse/presse-bckg.jpg"

const useStyles = makeStyles(theme => ({
  header: {
    paddingTop: 76,
    backgroundImage: props =>
      `url(${props.landingBackgroundUrl || landingBackground})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    display: "flex",
    flexDirection: "column",
  },
  missionHeader: {
    backgroundImage: `url(${missionBackground})`,
  },
  presseHeader: {
    backgroundImage: `url(${presseBackground})`,
  },
  logoCircled: {
    width: 56,
    height: 56,
    display: "block",
    margin: "0 auto",
    marginTop: 120,
  },
  textWrapper: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),
    color: "#FFF",
  },
  subtitle: {
    maxWidth: 576,
  },

  logoWrapper: {
    marginTop: theme.spacing(2),
    display: "flex",

    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(4),
    },
  },
  signUpButton: {
    width: 263,
    height: 44,
    minHeight: 44,
    borderRadius: 22,
    marginTop: theme.spacing(2),
    background: "linear-gradient(92.81deg, #FFC400 0%, #FFAB00 100%)",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.15)",
    ...theme.typography.body2,
    fontWeight: 500,
    textTransform: "initial",
    color: theme.palette.common.white,

    "&:hover": {
      backgroundColor: "transparent",
    },

    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(4),
    },
  },
  parternsWrapper: {
    // padding: theme.spacing(2),
    overflow: "hidden",
    minHeight: 90,
    backgroundColor: "#FFFAE6",
    borderBottom: `1px solid #FFF1D1`,
    color: "#FFC400",
  },
  parternsTitleWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    padding: theme.spacing(1),
  },
  partnerLogo: {
    height: 90,
  },
  iPhonesContent: {
    padding: theme.spacing(2),
    maxWidth: 1080,
    margin: "0 auto",
  },
  fullWidthColumnTitle: {
    marginTop: 120,
    overflowWrap: "break-word",
    wordWrap: "break-word",
    hyphens: "auto",
  },
  fullWidthColumnText: {
    maxWidth: 560,
    margin: "0 auto",
  },
  paragraphBlock: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  paragraphTextWrapper: {
    maxWidth: 440,
  },
  paragraphText: {
    marginTop: theme.spacing(0.5),
  },
  iPhone: {
    display: "block",
    margin: "0 auto",
    height: 600,
    objectFit: "cover",
  },
  column: {
    marginTop: 40,
  },
  columnMission: {
    marginTop: 120,
  },
  textColumn: {
    marginTop: theme.spacing(1),

    [theme.breakpoints.up("md")]: {
      marginTop: 40,
    },
  },
  textColumnMission: {
    marginTop: theme.spacing(1),

    [theme.breakpoints.up("md")]: {
      marginTop: 120,
    },
  },
  testimonialsWrapper: {
    overflowX: "hidden",
    marginTop: 40,
  },
  ethicsWrapper: {
    overflowX: "hidden",
    marginTop: 40,
    maxWidth: 1080,
    margin: "0 auto",

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2),
    },
  },
  fullWidthRow: {
    padding: theme.spacing(2),
  },
  iPhoneQuote: {
    marginTop: theme.spacing(3),
  },
  joinBannerWrapper: {
    marginTop: 120,
    marginBottom: 120,
    padding: theme.spacing(2),
  },
  joinBannerWrapperMission: {
    marginTop: 120,
    marginBottom: 0,
    padding: theme.spacing(2),
  },
  missionTextContent: {
    padding: theme.spacing(2),
  },
  textContentQuote: {
    margin: theme.spacing(2, 0),
  },
  squaredPictures: {
    objectFit: "contain",
    width: "100%",
    maxHeight: 500,
  },
  articlesWrapper: {
    maxWidth: 1080,
    margin: "0 auto",
  },
}))

export default useStyles
