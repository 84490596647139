import React from "react"

import Typography from "@material-ui/core/Typography"

import StoreBadge from "./storeBadge"

import useStyles from "./joinBanner.style"
import { useTheme } from "@material-ui/core/styles"
import { getImage } from "../../helpers"

export default function JoinBanner({
  gutterBottom,
  removeImage,
  title,
  description,
  image,
}) {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <div className={`${classes.root} ${gutterBottom && classes.gutterBottom}`}>
      <div className={classes.wrapper}>
        {!removeImage && image && (
          <img
            className={classes.iphones}
            src={image && getImage(image.file.url, true)}
            alt={image.title}
          />
        )}
        <div className={classes.textWrapper}>
          <Typography variant="h5" color="inherit">
            {title}&nbsp;
            <span role="img" aria-label="heart icon">
              ❤️
            </span>
          </Typography>
          <Typography variant="button" color="inherit">
            {description}
          </Typography>
          <div className={classes.storesBadges}>
            <StoreBadge store="apple" margin={theme.spacing(0, 1, 0, 0)} />
            <StoreBadge store="google" margin={theme.spacing(0, 0, 0, 1)} />
          </div>
        </div>
      </div>
    </div>
  )
}
