import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: theme.spacing(0, 2),
  },
  gutterBottom: {
    marginBottom: theme.spacing(4),
  },
  wrapper: {
    position: "relative",
    width: "100%",
    height: "100%",
    background: "linear-gradient(94.67deg, #FF9100 2.52%, #FFBB00 101.81%)",
    borderRadius: theme.spacing(2),
    boxShadow:
      "0px 3px 5px rgba(33, 43, 54, 0.2), 0px 0px 1px rgba(33, 43, 54, 0.31)",
    color: "#FFF",
    padding: theme.spacing(5),
    maxWidth: "1080px",
    margin: "0 auto",
    overflow: "hidden",
  },
  storesBadges: {
    display: "flex",
    marginTop: theme.spacing(2),
  },
  textWrapper: {
    position: "relative",
    zIndex: 1,
  },
  iphones: {
    zIndex: 0,
    display: "none",
    position: "absolute",
    top: -2,
    right: -5,
    height: "calc(100% + 10px)",

    "@media screen and (min-width: 1048px)": {
      display: "block",
    },
  },
}))

export default useStyles
