import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    borderLeft: "1px solid #919EAB",
    paddingLeft: theme.spacing(2),
    color: "#919EAB",
  },
}))

export default useStyles
